import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import CtaButton from "./CtaButton/CtaButton";

const Footer = () => {
  return (
    <div>
      <footer className="z-0 relative footer relative bg-[#93d7e2] text-gray-600 py-14">
        <div className="container-normal mx-auto flex flex-col lg:flex-row justify-between px-6 relative z-10">
          {/* Informacje o firmie */}
          <div className="footer-info w-full lg:w-1/2 flex flex-col items-start mb-6 lg:mb-0">
            <h2 className="text-3xl font-bold mb-2 text-[#484747FF]">
              ContiPack Sp z o.o.
            </h2>
            <div className="text-[#484747FF]">
              <p className="text-base lg:text-lg text-[#484747FF] leading-relaxed mb-4">
                Przemysłowa 166
                <br />
                62-510 Konin
                <br />
                NIP: 6652989589
                <br />
                KRS: 0000792638
              </p>
            </div>
            <h3 className="text-3xl font-bold mb-2 text-[#484747FF]">
              Magazyn
            </h3>
            <div className="text-lg text-gray-600">
              <p className="text-base lg:text-lg text-[#484747FF] leading-relaxed mb-4">
                Centrum Logistyczne Eurocash <br />
                Kasztelańska 35 <br />
                62-571 Krągola
              </p>
            </div>
            <CtaButton className="black-cta-button" href="/kontakt" as="link">
              Kontakt
            </CtaButton>
          </div>
        </div>

        {/* Obrazek */}
        <div className="footer-image absolute inset-y-0 right-0 hidden lg:block">
          <StaticImage
            src="../images/footer-image.jpg"
            alt="Obrazek Footer"
            layout="constrained" // Umożliwia kontrolowanie proporcji
            objectFit="contain" // Skaluje obraz do kontenera, zachowując proporcje
            objectPosition="right center" // Ustawia obraz na prawo
            className="h-full"
          />
        </div>
      </footer>
      <div className="z-100 relative bg-transparent py-2 mt-[-50px] flex justify-center items-center">
        <Link
          className="text-[#484747] font-bold hover:text-[#2c2b2b]"
          to="https://www.contipack.pl/polityka-prywatnosci/"
        >
          Polityka Prywatności
        </Link>
      </div>
    </div>
  );
};

export default Footer;
