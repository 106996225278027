// fonts
// import "@fontsource/poppins/500.css";
// import "@fontsource/poppins/700.css";
// import "@fontsource/poppins/600.css";
// import "@fontsource/inter/500.css";

import React from "react";
import Layout from "./src/components/Layout";
import "./src/globalstyle/index.css";

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);
