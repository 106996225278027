import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Logo = () => (
  <div className="logo flex items-center">
    <Link to="/">
      <StaticImage
        src="../images/logo.png"
        alt="logo"
        className="w-[120px] xl:w-[140px] h-auto object-contain"
        layout="constrained"
        placeholder="none"
      />
    </Link>
  </div>
);

export default Logo;
