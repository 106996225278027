import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Logo from "../images/logo.png";

import { SearchModalContextProvider } from "../context/SearchModalContextProvider";
import Search from "./search/SearchModal";

import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      sanitySite {
        siteIsOff
        disabledMessage
        disabledPhone
        disabledEmail
      }
      sanitySite {
        gtmId
      }
    }
  `);

  const {
    siteIsOff = false, // Domyślna wartość
    disabledMessage = "Strona jest aktualnie niedostępna.", // Domyślny komunikat
    disabledPhone = "Brak numeru telefonu", // Domyślny telefon
    disabledEmail = "Brak adresu email", // Domyślny email
  } = data?.sanitySite || {}; // Bezpieczne odwołanie

  // Jeśli strona jest wyłączona, renderujemy komunikat o wyłączeniu
  if (siteIsOff) {
    return (
      <div className="flex items-center justify-center h-screen w-full bg-white">
        <div className="text-center container-normal flex-col">
          {/* Logo */}
          <img
            src={Logo}
            alt="Konplan Logo"
            className="mx-auto mb-6"
            width={200}
          />

          {/* Komunikat o niedostępności */}
          <h1 className="text-3xl font-bold w-4/5 m-auto">{disabledMessage}</h1>

          {/* Informacje kontaktowe */}
          <div className="mt-6 space-y-2">
            <p className="text-lg font-medium">Kontakt:</p>
            <p className="text-xl">
              <strong>Telefon:</strong> {disabledPhone}
            </p>
            <p className="text-xl">
              <strong>Email:</strong>{" "}
              <a
                href={`mailto:${disabledEmail}`}
                className="text-blue-500 hover:underline"
              >
                {disabledEmail}
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (children?.key === "/404.html") {
    return (
      <SearchModalContextProvider>
        <GlobalStyles />
        <Search />
        <Header />
        <main>{children}</main>
      </SearchModalContextProvider>
    );
  }

  return (
    <SearchModalContextProvider>
      <Search />
      <Header />
      <main>{children}</main>
      <Footer />
    </SearchModalContextProvider>
  );
};

export default Layout;
