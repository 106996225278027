exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-naczynia-z-trzciny-cukrowej-js": () => import("./../../../src/pages/naczynia-z-trzciny-cukrowej.js" /* webpackChunkName: "component---src-pages-naczynia-z-trzciny-cukrowej-js" */),
  "component---src-pages-slomki-papierowe-js": () => import("./../../../src/pages/slomki-papierowe.js" /* webpackChunkName: "component---src-pages-slomki-papierowe-js" */),
  "component---src-pages-sposob-pakowania-js": () => import("./../../../src/pages/sposob-pakowania.js" /* webpackChunkName: "component---src-pages-sposob-pakowania-js" */),
  "component---src-pages-sztucce-drewniane-js": () => import("./../../../src/pages/sztucce-drewniane.js" /* webpackChunkName: "component---src-pages-sztucce-drewniane-js" */),
  "component---src-templates-author-list-js": () => import("./../../../src/templates/author-list.js" /* webpackChunkName: "component---src-templates-author-list-js" */),
  "component---src-templates-preview-js": () => import("./../../../src/templates/preview.js" /* webpackChunkName: "component---src-templates-preview-js" */),
  "component---src-templates-single-author-js": () => import("./../../../src/templates/single-author.js" /* webpackChunkName: "component---src-templates-single-author-js" */),
  "component---src-templates-single-blog-js": () => import("./../../../src/templates/single-blog.js" /* webpackChunkName: "component---src-templates-single-blog-js" */),
  "component---src-templates-single-category-js": () => import("./../../../src/templates/single-category.js" /* webpackChunkName: "component---src-templates-single-category-js" */),
  "component---src-templates-single-page-js": () => import("./../../../src/templates/single-page.js" /* webpackChunkName: "component---src-templates-single-page-js" */)
}

