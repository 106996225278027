import React from "react";
import { TfiFacebook } from "react-icons/tfi";
import { AiFillYoutube } from "react-icons/ai";
import { FaInstagram } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import { useSearchModal } from "../context/SearchModalContextProvider";

const Contact = () => {
  const { openSearchModal } = useSearchModal(); // Uzyskaj dostęp do funkcji otwierającej modal

  return (
    <div className="contact hidden lg:flex justify-center items-center rounded-l-full space-x-2">
      {/* Facebook */}
      <a
        href="https://www.facebook.com/ContipackKonin"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Facebook"
        className="w-10 h-10 flex justify-center items-center bg-blue-600 hover:bg-blue-700 rounded-full text-white"
      >
        <TfiFacebook className="text-[1.5rem] p-[2px]" />
      </a>

      {/* YouTube */}
      <a
        href="https://www.youtube.com/@ContiPack_PL"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="YouTube"
        className="w-10 h-10 flex justify-center items-center bg-red-600 hover:bg-red-700 rounded-full text-white"
      >
        <AiFillYoutube className="text-[1.5rem]" />
      </a>

      {/* Instagram */}
      <a
        href="https://www.instagram.com/contipack/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Instagram"
        className="w-10 h-10 flex justify-center items-center rounded-full text-white bg-gradient-to-br from-[#F58529] via-[#DD2A7B] to-[#515BD4]"
      >
        <FaInstagram className="text-[1.5rem] p-[2px]" />
      </a>

      {/* Przycisk wyszukiwania, który otworzy modal */}
      <FaSearch
        onClick={openSearchModal} // Wywołanie funkcji otwierającej modal
        className="bg-gray-800 hover:bg-gray-900 rounded-full p-2.5 w-10 h-10 text-white cursor-pointer"
        aria-label="Search"
      />
    </div>
  );
};

export default Contact;
