import React from "react";
import { LuCircleArrowRight } from "react-icons/lu";
import { Link } from "gatsby";

const CtaButton = ({
  children,
  className = "cta-button",
  type = "button",
  as = "button",
  link, // dynamiczny link
  href, // alternatywny prop
  ariaLabel,
}) => {
  const dynamicLink = link || href;
  const defaultAriaLabel = "Call to action button";

  if (as === "link" && dynamicLink) {
    const isExternalLink = /^https?:\/\//.test(dynamicLink);

    if (isExternalLink) {
      return (
        <a
          href={dynamicLink}
          target="_blank"
          rel="noopener noreferrer"
          className={`${className} mt-4`}
          aria-label={ariaLabel || defaultAriaLabel}
        >
          {children}{" "}
          <LuCircleArrowRight className="ml-3 text-2xl md:text-2xl" />
        </a>
      );
    }

    return (
      <Link
        to={dynamicLink}
        className={`${className} mt-4`}
        aria-label={ariaLabel || defaultAriaLabel}
      >
        {children} <LuCircleArrowRight className="ml-3 text-2xl md:text-2xl" />
      </Link>
    );
  }

  return (
    <button
      type={type}
      className={`${className} mt-4`}
      aria-label={ariaLabel || defaultAriaLabel}
    >
      {children} <LuCircleArrowRight className="ml-3 text-2xl md:text-2xl" />
    </button>
  );
};

export default CtaButton;
