import React from "react";

import Logo from "./Logo";
import Navigation from "./Navigation";
import Contact from "./Contact";

const Header = () => {
  return (
    <header id="start" className="w-full z-10 mb-5 xl:mb-2">
      <div className="container-normal justify-between items-center relative z-10 h-[100px] lg:h-[120px] xl:h-[130px] 2xl:h-[140px]">
        <Logo />
        <Navigation />
        <div className="hidden lg:flex">
          <Contact />
        </div>
      </div>
      {/* <div className="top-0 right-0 lg:w-[185px] xl:w-[210px] 2xl:w-[300px] h-[96px] z-0"></div> */}
    </header>
  );
};

export default Header;
